import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Speaking = () => (
  <Layout>
  <SEO title="Speaking" />
  <div className="summary">
    <p>
      I speak regularly on JavaScript and general programming principles.
    </p>
  </div>
  <div>
  <h2>Current Talks:</h2>
  <ul>
    <li> Principles of Modern JavaScript </li>
      <ul>
        <li>
          Presented to thousands of developers both nationally and internationally. Also available on Pluralsight.
        </li>
        <li>
          Presented at KCDC 2018, That Conference 2018, CodeMash 2020, CodeBar 2020 (Europe)
        </li>
        <li>
          <a href="https://www.pluralsight.com/courses/codemash-session-33">
            Video
          </a>
        </li>
        <li>
          <a href="https://docs.google.com/presentation/d/1tT0V3DG4O5XBzJpxfTQ2E2OyqxKgdi0jsmCCn7La5Ms/edit?usp=sharing">
            Slides
          </a>
        </li>
        <li>
          <a href="https://twitter.com/jessitron/status/1026563520840835072w">
            Selected Praise
          </a>
        </li>
        <li>
          <a href="https://twitter.com/TaylorKrusen/status/1026564325895532546">
            Selected Praise
          </a>
        </li>
      </ul>
    <li>Microfrontends with SystemJS and Single Spa</li>
    <ul>
      <li>Presented at Kansas City React Meetup</li>
      <li>Led to team consulations in the Kansas City Area</li>
      <li><a href="https://www.youtube.com/watch?v=5Fs3fXw1sBM">
        Recording
        </a>
      </li>
    </ul>
    <li>The Gray Areas: Confronting Ethical Challenges in Programming</li>
    <ul>
      <li>Presented at KCDC 2019</li>
      <li><a href="https://docs.google.com/presentation/d/1odEH_DEJyD2eKm3Ur0VH_0ia_KE9Bhue6gnadKCvPZY/edit?usp=sharing">
        Slides
        </a>
      </li>
    </ul>
    <li>Less. Better. Let's Refactor JavaScript</li>
    <ul>
      <li>Presented at KCDC 2019. Delivered as a 4 hour workshop at CodeMash 2020.</li>
      <li><a href="https://www.youtube.com/watch?v=19oPzwKm94k">Video of short version presented at local meetup</a></li>
      <li><a href="https://docs.google.com/presentation/d/1gGJmQmXzqQRG_ZGkTKqyYFBqDKkKU8OFV8YCaG-jA1E/edit?usp=sharin://docs.google.com/presentation/d/1gGJmQmXzqQRG_ZGkTKqyYFBqDKkKU8OFV8YCaG-jA1E/edit?usp=sharing">
        Slides
        </a>
      </li>
      <li><a href="https://github.com/jsmapr1/refactoring-talk">Code Repo
          </a>
      </li>
    </ul>

  </ul>
  <h2>Selected Talks:</h2>
  <ul>
    <li>ES6 Patterns in the Wild: Learning new syntax by reading open source code</li>
    <ul>
      <li>Presented at CodeMash 2017</li>
      <li>
        <a href="https://docs.google.com/presentation/d/1kI1ROqRtMlRISyBw3a5NB3Aoy8v1F4VDGGExNtgsVEc/edit?usp=sharing">
          Slides
        </a>
      </li>
      <li>
        <a href="https://twitter.com/_JoshSommer/status/819619958716059649">
        Selected Praise
        </a>
      </li>
    </ul>
    <li>Unbreakable: The Craft of Code</li>
      <ul>
        <li>Presented at KCDC 2017 and CodeMash 2018 to standing room audiences</li>
        <li>
          <a href="https://docs.google.com/presentation/d/1FiGwFvISfk1X_2Qs2Jmi1Zf5AedmYwk2g9en531REiY/edit?usp=sharing">
            Slides
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=cT7TMzZ3Cnw">
            Video
          </a>
        </li>
        <li>
          <a href="https://twitter.com/bmac_editor/status/951447931483250688">
            Selected Praise
          </a>
        </li>
      </ul>
  </ul>
  <p>
  I have also presented talks on SVGs, career anti-patterns, and many talks on react and JavaScript at local meet ups.
  </p>
  </div>
  </Layout>
)

export default Speaking
